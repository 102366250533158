import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./../../Router/RouterConfig";
import "./Navbar.scss";
import { profileData } from "./../../Pages/About/AutoritiesOfUniversity/ProfileData";
import {
  ACADEMIC_CALENDAR_GET,
  ADD_COMMITTEES,
  STAFF_DETAILS_NEW_GET,
} from "../../Utils/InfoUploadingConst";

function Navbar() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [staffdata, setStaffData] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_COMMITTEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("hi");

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111006
        );
        console.log("activeData -", activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: ACADEMIC_CALENDAR_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        // res.data.data.sort((a, b) => b.session - a.session);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111006
        );
        setData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: STAFF_DETAILS_NEW_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111006
        );
        setStaffData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Navbar2">
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-4">
              <div className="header-right-content">
                <marquee className="scroll-text text-white">
                  <p className="text-white">
                  Notification :
                    <a href="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sims/MBBS+Result+Notification+-+31-08-2024.pdf" className="text-light">
                    Revised Results of Jul-Aug 2024 Examinations of 1st Year MBBS
                    </a> &nbsp;&nbsp;&nbsp;&nbsp;
                    Toll free number to report ragging :{" "}
                    <a href="tel:1800 843 2900" className="text-light">
                      1800 843 2900
                    </a>{" "}
                  </p>
                </marquee>
              </div>
            </div>

            <div className="col-lg-8 col-sm-8">
              <div className="header-right-content">
                <div className="list">
                  <ul>
                    <li>
                      <a
                        href={"https://www.swaminarayanuniversity.ac.in/"}
                        target="_blank"
                        className="nav-link"
                      >
                        Swaminarayan University
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.nmc.org.in/"
                        target="_blank"
                        className="nav-link"
                      >
                        National Medical Council
                      </a>
                    </li>
                    <li>
                      <Link to={"/sims"} target="_blank" className="nav-link">
                        Admission Committee
                      </Link>
                    </li>
                    <li>
                      <Link to={"/sims"} target="_blank" className="nav-link">
                        Fee Regulatory Committee
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link to={"/sims/Recruitment"} className="nav-link">
                        Recruitment
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="navbar-area nav-bg-1">
          <div className="mobile-responsive-nav">
            <div className="container">
              <div className="mobile-responsive-menu">
                <div className="logo">
                  <a href="/">
                    <img
                      src="/assets/images/logo.png"
                      className="main-logo"
                      lt="logo"
                    />
                    <img
                      src="/assets/images/white-logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/sims">
                  <img
                    src="/assets/images/white-logo.png"
                    width="250px"
                    alt="logo"
                  />
                </a>
                <h5 class="text-center">
                  Swaminarayan Institute of Medical Sciences and Research, Kalol
                </h5>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link to={"/sims"}>Home</Link>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="College"
                      >
                        About Us <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        {/* <li className="nav-item">
                          <Link to={"/sims/AboutCollege"}>About College</Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/sims/missionVision"}>
                            Vision & Mission
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/sims/deanMedicalSuperintendentMessage"}>
                            Dean and Superintendent Message
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/sims/citizenCharter"}>
                            Citizen Charter
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/sims/FoundersMessage"}>
                            Founder Message
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                              <Link to={"/sims/VicePresidentMsg"} className="nav-link">
                                Vice President Msg
                              </Link>
                        </li> */}
                        {/* <li className="nav-item">
                            <Link to={"/sims/Provost(Vice-Chancellor)"}>
                              Provost (Vice-Chancellor)
                            </Link>
                          </li> */}
                        {/* <li className="nav-item">
                            <Link to={"/sims/Registrar"}>
                              Registrar
                            </Link>
                          </li> */}
                        {/* <li className="nav-item">
                            <Link to={"/sims/Advisor"}>
                              Advisor
                            </Link>
                          </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Committees"
                      >
                        Committees <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        {info.filter((d) => d.type === "College Council")
                          .length !== 0 ? (
                          info
                            .filter((d) => d.type === "College Council")
                            .map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  College Council
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="" target="_blank" className="nav-link">
                              College Council
                            </a>
                          </li>
                        )}

                        {info.filter(
                          (d) => d.type === "Internal Complaints Committee"
                        ).length !== 0 ? (
                          info
                            .filter(
                              (d) => d.type === "Internal Complaints Committee"
                            )
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Internal Complaints Committee
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="" target="_blank" className="nav-link">
                              Internal Complaints Committee
                            </a>
                          </li>
                        )}

                        {info.filter((d) => d.type === "Anti-Ragging Committee")
                          .length !== 0 ? (
                          info
                            .filter((d) => d.type === "Anti-Ragging Committee")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Anti-Ragging Committee
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="" target="_blank" className="nav-link">
                              Anti-Ragging Committee
                            </a>
                          </li>
                        )}

                        {info.filter((d) => d.type === "Ethics Committee")
                          .length !== 0 ? (
                          info
                            .filter((d) => d.type === "Ethics Committee")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Ethics Committee
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="" target="_blank" className="nav-link">
                              Ethics Committee
                            </a>
                          </li>
                        )}

                        {info.filter((d) => d.type === "Medical Education")
                          .length !== 0 ? (
                          info
                            .filter((d) => d.type === "Medical Education")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Medical Education
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="" target="_blank" className="nav-link">
                              Medical Education
                            </a>
                          </li>
                        )}

                        {info.filter((d) => d.type === "Curriculum Committee")
                          .length !== 0 ? (
                          info
                            .filter((d) => d.type === "Curriculum Committee")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Curriculum Committee
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a href="" target="_blank" className="nav-link">
                              Curriculum Committee
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Academics <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          {/* <Link to={"/sims/admissions"}>
                            {" "}
                            Yearly Planner (AY 2023-24)
                          </Link> */}
                          <Link to={"/sims/YearlyPlanner"}>
                            {" "}
                            Yearly Planner
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link to={"/sims/TimeTable"}> Time Table </Link>
                        </li>

                        {/* {data?.filter((d) => d.type === "Fee Structure").length !== 0 ? (
                            data
                            ?.filter((d) => d.type === "Fee Structure")
                            ?.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <a href={item?.attachments} target="_blank" className="nav-link">
                                Fee Structure
                              </a>
                            </li>
                            ))
                              ) 
                              : 
                                <li className="nav-item" >
                                  <a href="/sims" target="_blank" className="nav-link">
                                    Fee Structure
                                  </a>
                                </li>
                          } */}

                        {/* {data?.filter((d) => d.type === "Courses Details").length !== 0 ? (
                            data
                            ?.filter((d) => d.type === "Courses Details")
                            ?.map((item, index) => (
                            <li className="nav-item" key={index}>
                              <a href={item?.attachments} target="_blank" className="nav-link">
                                Courses
                              </a>
                            </li>
                            ))
                              ) 
                              : 
                                <li className="nav-item" >
                                  <a href="/sims" target="_blank" className="nav-link">
                                    Courses
                                  </a>
                                </li>
                          } */}

                        {/* <li className="nav-item">
                          <Link to={"/sims/Achievements-Awards-Medals"}>Achievements</Link>
                        </li> */}

                        <li className="nav-item">
                          <Link to={"/sims/Examination"}>Examination</Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/sims/studentList"}>Students Lists</Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/sims/Results"}>Results</Link>
                        </li>
                        <li className="nav-item">
                          <a href="https://forms.gle/baZxXvfu19fHXFQ8A" target="_blank" rel="noopener noreferrer">Re-checking/Re-assessment</a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="/sims/AwardsandMedals"
                            title="AwardsandMedals"
                          >
                            Awards & Medals
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <Link to="/sims/AcademicCalendar">
                            {" "}
                            Academic Calender
                          </Link>
                        </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Hospital"
                      >
                        Info as per NMC Guidelines <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        {/* <li className="nav-item">
                          <Link to={"/sims/AboutCollege"}>About College</Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/sims/AboutCollege"}>College</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/sims/deanMedicalSuperintendent"}>
                            Dean
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/sims/hospital"}>Hospital</Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/sims/Hospital-Staff-Attendance"}>
                            Hospital Staff Attendance
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <a href="/sims/HospitalDetails" className="nav-link">
                            Hospital Details
                          </a>
                          {/* <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a href="#" className="nav-link dropdown-toggle">
                                Specialities
                              </a>
                              <ul className="dropdown-menu">
                                <li className="nav-item">
                                  <Link
                                    to={"/services/generalMedicine"}
                                    className="nav-link"
                                  >
                                    General Medicine
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/generalSurgery"}
                                    className="nav-link"
                                  >
                                    General Surgery
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/ent"}
                                    className="nav-link"
                                  >
                                    ENT
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/anesthesiology"}
                                    className="nav-link"
                                  >
                                    Anesthesiology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/dermatology"}
                                    className="nav-link"
                                  >
                                    Dermatology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/orthopedic"}
                                    className="nav-link"
                                  >
                                    Orthopedic
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/dentistry"}
                                    className="nav-link"
                                  >
                                    Dentistry
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/respiratoryMedicine"}
                                    className="nav-link"
                                  >
                                    Respiratory Medicine
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/obstetricsAndGynecology"}
                                    className="nav-link"
                                  >
                                    Obstetrics and Gynecology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/ophthalmology"}
                                    className="nav-link"
                                  >
                                    Ophthalmology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/psychiatry"}
                                    className="nav-link"
                                  >
                                    Psychiatry
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/services/physiotherapy"}
                                    className="nav-link"
                                  >
                                    Physiotherapy
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="nav-item">
                              <a href="#" className="nav-link dropdown-toggle">
                                Super Specialities
                              </a>
                              <ul className="dropdown-menu">
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Neuro Surgery
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Urology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Nephrology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Neonatology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Burns + Plastics Surgery
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Vascular Surgery
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Gastrology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Pain Management
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Spine Surgery
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Neurology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link to={"/"} className="nav-link">
                                    Oncology
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="nav-item">
                              <a href="#" className="nav-link dropdown-toggle">
                                Services
                              </a>
                              <ul className="dropdown-menu">
                                <li className="nav-item">
                                  <Link
                                    to={"/service/IPDOPDServices"}
                                    className="nav-link"
                                  >
                                    OPD / IPD Services
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/EmergencyServices"}
                                    className="nav-link"
                                  >
                                    Emergency Services
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/CentralLaboratory"}
                                    className="nav-link"
                                  >
                                    Central Laboratory
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/Radiology"}
                                    className="nav-link"
                                  >
                                    Radiology
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/Pharmacy"}
                                    className="nav-link"
                                  >
                                    24*7 Pharmacy
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/BloodBank"}
                                    className="nav-link"
                                  >
                                    Blood Bank
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/Dialysis"}
                                    className="nav-link"
                                  >
                                    Dialysis
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/Ambulance"}
                                    className="nav-link"
                                  >
                                    Ambulance
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/PMJAY"}
                                    className="nav-link"
                                  >
                                    PMJAY
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/VaccinationServices"}
                                    className="nav-link"
                                  >
                                    Vaccination Services
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    to={"/service/CommunityHealthandOutreach"}
                                    className="nav-link"
                                  >
                                    Community Health and Outreach
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li className="nav-item">
                              <Link
                                to={"/services/healthCheckup"}
                                className="nav-link"
                              >
                                Health Checkup
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link to={"/services/Doctors"} className="nav-link">
                                Doctors
                              </Link>
                            </li>
                          </ul> */}
                        </li>

                        <li className="nav-item">
                          <a
                            href={
                              "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sims/LOP+23.03.2023.pdf"
                            }
                            target="_blank"
                          >
                            LOP & Seats
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link to={"/sims/StatusandRecognition"}>
                            Status of Recognition
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/sims/intakeSanctionedCapacity"}>
                            MBBS Seats
                          </Link>
                        </li>
                        <li className="nav-item">
                          {/* <Link to={"/sims/pgSeats"}> */}
                          <Link to={"/sims/Pgseats"}>PG Seats</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/sims/Hostel" className="nav-link">
                            {/* Hostel (Fees) */}
                            Hostel
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/sims/GrievanceRedressalOfficer"}>
                            Grievance Redressal Officer
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/sims/Permission"}>
                            College Permission
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href={
                              "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sims/MBBS-Notification+of+Affiliation.pdf"
                            }
                            target="_blank"
                          >
                            {" "}
                            Affiliation University
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a href="#" className="nav-link dropdown-toggle">
                            Department & Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <Link to={"/staff/Anatomy"} className="nav-link">
                                Anatomy
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/staff/Physiology"}
                                className="nav-link"
                              >
                                Physiology
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/staff/Biochemistry"}
                                className="nav-link"
                              >
                                Biochemistry
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to={"/staff/Pharmacology"}
                                className="nav-link"
                              >
                                Pharmacology
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Pathology"}>Pathology</Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Microbiology"}>
                                Microbiology
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/ForensicMedicine"}>
                                Fornsic Medicine
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/CommunityMedicine"}>
                                Community Medicine
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/GeneralMedicine"}>
                                General Medicine
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Pediatrics"}>Pediatrics</Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Dermatology"}>Dermatology</Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Psychiatry"}>Psychiatry</Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/GeneralSurgery"}>
                                General Surgery
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Orthopedics"}>Orthopedics</Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Ophthalmology"}>
                                Ophthalmology
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Otorhinolaryngology"}>
                                Otorhinolaryngology
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Orthopedics"}>Orthopedics</Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/ObstetrcisGynecology"}>
                                Obstetrcis & Gynecology
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Radiodiagnosis"}>
                                Radiodiagnosis
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to={"/staff/Anesthesia"}>Anesthesia</Link>
                            </li>

                            <li className="nav-item">
                              <Link to={"/staff/Dentistry"}>Dentistry</Link>
                            </li>
                          </ul>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/sims/researchandPulication"}>Research and Publication</Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/sims/academicsActivities"}>
                            Grievance Redressal Officer
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/sims/status"}>Status of Recognition</Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/sims/intakeSanctionedCapacity"}>
                            MBBS Seats
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/sims/Permission"}>
                            College Permission
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <a
                            href={
                              "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/sims/MBBS-Notification+of+Affiliation.pdf"
                            }
                            target="_blank"
                          >
                            {" "}
                            Affiliation University
                          </a>
                        </li> */}

                        <li className="nav-item">
                          <Link to={"/sims"}>PG Courses</Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to="/sims/Permission" title="Permission">
                            Permission
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to="/sims/Affiliation" title="Affiliation">
                            Affiliation
                          </Link>
                        </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Staff"
                      >
                        Facilities <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to="/sims/Transportation" className="nav-link">
                            Transport
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/sims/Library" className="nav-link">
                            Library
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/sims/ResidentialFacilities"
                            className="nav-link"
                          >
                            {/* Hostel (Fees) */}
                            Hostel
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"/services/OpdServices"}>OPD Services</Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link to={"/services/IpdServices"}>IPD Services</Link>
                        </li> */}
                      </ul>
                    </li>
                    {/* <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Staff"
                      >
                        Staff <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to="/sims/teachingStaff"> Teaching Staff</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/sims/non-teachingStaff">
                            {" "}
                            Non-Teaching Staff
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to={"/sims/events"}>Events</Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to={"/sims/gallery"}>Gallery</Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to={"/sims/contactUs"}>Contact Us</Link>
                    </li> */}
                    {/* <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Important Links"
                      >
                        Important Links <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                      <li>
                      <a
                        // href={"https://www.swaminarayanuniversity.ac.in/"}
                        href="#"
                        target="_blank"
                        className="nav-link"
                      >
                        Swaminarayan University
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        target="_blank"
                        className="nav-link"
                      >
                        National Medical Council
                      </a>
                    </li>
                    <li>
                      <Link to={"/sims"} target="_blank" className="nav-link">
                        Admission Committee
                      </Link>
                    </li>
                    <li>
                      <Link to={"/sims"} target="_blank" className="nav-link">
                        Fee Regulatory Committee
                      </Link>
                    </li>
                      </ul>
                    </li> */}
                    <li className="nav-item">
                      <Link to={"/sims/events"}>Events</Link>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Contact Us"
                      >
                        Contact Us <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <Link to={"/sims/ContactUs"}>Contact Us</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/sims/Recruitment"} className="nav-link">
                            Recruitment
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a href="#" title="Member Menu">
                        <i className="glyphicon glyphicon-user" />
                      </a>
                    </li>{" "}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar;

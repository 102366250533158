import React from 'react'

function AboutCollegeEngineering() {
  return (
    <div><>
    {/* Start For Content */}
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-3 mt-4">
        
            <img
              src="https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/faculties/homoeopathy/assets/1637632899_2902-org.jpg"
              alt=""
              className="img-responsive img-thumbnail"
              width={361}
              height={461}
            />
            <br />
            <br />

       
       
        </div>
        <div className="col-md-9">
          <h2>
          About College
            <br />
          </h2>
          <p style={{ textAlign: "justify" }}>
      
          Established in 1992, Sri Swaminarayan Vishwamangal Gurukul introduced
          </p>
          <p  style={{ textAlign: "justify" }}>
          In Continuation of the true spirit of charity, SHREE SWAMINARAYAN
          VISHVAMANGAL GURUKUL, KALOL is equality and strongly committed to the
          philosophy of offering technology-centered education routed in the
          ideas of charity and philanthropy. The Trust is a distinguished
          organization committed to the multidimensional growth of education
          fraternity. The trust is well aware of the social, administrative and
          technological needs of contemporary society, which it targets to
          fulfill through education.
            
        </p>
          <p>Swaminarayan College of Engineering and Technology with Diploma courses in 2009</p>
          <ol>
          <li>Diploma In </li>
          <li>Automobile, Civil, Computer, Electrical &amp; Mechanical</li>
            
          </ol>
          <p>In the year 2012 the institute added Degree programs in Engineering.</p>
          <ol>
          <li>Automobile, Civil, Computer, Electrical &amp; Mechanical</li>
            <li>In 2022 UG in Information Technology was also introduced</li>
            <li>Now the institute has PG Civil(Structure), Computer Engg, Electrical Engg, Mechanical (CAD/CAM
and Ph D courses also</li>
          </ol>
          <p>
          At Swaminarayan College of Engineering &amp; Technology we are committed to nurturing the next
generation of engineers and problem solvers. Our comprehensive degree courses are designed to equip
with not only technical skills but also critical thinking, creativity, and a deep understanding of the societal
impact of engineering. Our distinguished faculty members, renowned in their respective fields, are
passionate educators and dedicated mentors. They will guide you through a curriculum that blends
theoretical knowledge with practical hands-on experience, ensuring that you are well-prepared to tackle
real-world challenges. The institute has developed with adequate general campus facilities such as office
space, faculty rooms, library, classrooms, seminar hall, canteen, wash rooms, student amenities and
residential facilities for students and faculty to facilitate the conduct of the various academic, research
and extension activities associated with the teaching and learning processes.
          </p>
          
        </div>
      </div>
      <br />
      <br />
    </div>{" "}
    {/* End For Content */}
    
  </>
  </div>
  )
}

export default AboutCollegeEngineering;
